import axios from "axios";
import appState from "../state/AppStateContainer";
import { AppSettings } from "../constants/AppSettings";
import _ from "lodash";
import { message } from "antd";
import configureStore, { history } from "../appRedux/store";

export default class BaseService {
  constructor() {
    axios.interceptors.request.use(
      async (config) => {
        //Need to change config.baseURL according to release (e.g for development, its Localhost)
        config.baseURL =
          process.env.REACT_APP_API_BASEURL ||
          AppSettings.ApiUrls.PakistanProduction;

        if (appState.state.token) {
          config.headers.authorization = "Bearer " + appState.state.token;
          config.headers.token = appState.state.token;
        } else if (config.url != "/auth/createsession") {
          const response = await CreateSession();
          if (response && response.data.APICode == 0) {
            let updatedToken = await appState.setToken(
              response.data.Data.Token
            );
            config.headers.authorization = "Bearer " + response.data.Data.Token;
            config.headers.token = appState.state.token;
          }
        }
        console.log("axios", config.url);
        return config;
      },

      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    async function CreateSession() {
      const response = axios.post("/auth/createsession", {
        Platform: "web",
        CultureCode: "en-US",
        DeviceToken: "",
        Module: 5, //MerchantWebApp
      });
      return response;
    }
    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        // Do something with response data
        return response;
      },
      async function (error) {
        if (
          _.get(error, "response.status") === 401 ||
          _.get(error, "response.status") === 403
        ) {
          //localStorage.removeItem("token");
          //localStorage.removeItem("user_id");
          localStorage.clear();
          window.location = "/";
        }

        if (error.message === "Network Error") {
          //window.location = "/error";
          history.push("/error");
        }
        return Promise.reject(error);
      }
    );
  }
}
