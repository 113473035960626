import React from "react";
import IntlMessages from "util/IntlMessages";
import { AppSettings } from "../../../../constants/AppSettings";

export const paymentOptions = [
  {
    label: <IntlMessages id="config.cashOnDelivery" />,
    text: "cash on delivery",
    value: 1,
    flag: 1,
  },
  //{ label: <IntlMessages id="config.klarna" />, value: 2, flag: 1 << 2 },
  //{ label: 'Dibis', value: 4, flag: 1 << 4 },
  { label: "Swish", text: "swish", value: 8, flag: 8 },
  // { label: <IntlMessages id="config.bambora" />, value: 16, flag: 1 << 16 },
  // { label: "Swedbank Pay", value: 64, flag: 1 << 64 },
  { label: "Stripe", text: "stripe", value: 128, flag: 128 },
  { label: "Etisalat (UBL)", text: "etisalat", value: 256, flag: 256 },
  { label: "Credit", text: "credit", value: 512, flag: 512 },
  {
    label: <IntlMessages id="config.cardTerminal" />,
    text: "card terminal",
    value: 32,
    flag: 32,
  },
  {
    label: <IntlMessages id="config.faktura" />,
    text: "faktura",
    value: 1024,
    flag: 1024,
  },
];

export const chargeablePaymentMethods = [
  {
    label: "Etisalat (UBL)",
    value: 256,
    flag: 256,
    country: AppSettings.Country.Pakistan,
  },
  {
    label: "Stripe",
    value: 128,
    flag: 128,
    country: AppSettings.Country.Pakistan,
  },
  {
    label: "Stripe",
    value: 128,
    flag: 128,
    country: AppSettings.Country.Sweden,
  },
];
